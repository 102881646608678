<template>
  <div style="padding: calc(10px + 1vw)">
    <div>
      <h2 style="text-align: center">{{ $t("reports_executions.title") }}</h2>
    </div>
    <div>
      <v-row class="date-filter-container">
        <v-col cols="5" class="date-picker">
          <v-menu v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px"
            min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="startDate" :label="$t('document.start_date')" prepend-icon="mdi-calendar" readonly
                v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="startDate" no-title @input="menu1 = false">
            </v-date-picker>
          </v-menu>
          <p @click="startDate = ''" class="pointer">
            <v-icon>mdi-close</v-icon>
          </p>
        </v-col>
        <v-col cols="5" class="date-picker">
          <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px"
            min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="endDate" :label="$t('document.end_date')" prepend-icon="mdi-calendar" readonly
                v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="endDate" no-title @input="menu2 = false">
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-menu>
          <p @click="endDate = ''" class="pointer">
            <v-icon>mdi-close</v-icon>
          </p>
        </v-col>
        <v-col cols="2" class="d-flex justify-space-between">
          <v-btn depressed color="primary" @click="handleClick">
            {{ $t("search") }}
          </v-btn>
          <v-btn depressed color="primary px-2" @click="handleDownload">
            {{ $t("download") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-data-table hide-default-header :headers="headers" :items="items" :items-per-page="15" class="table-report"
      :footer-props="{
        itemsPerPageOptions: [20, 50, 100, -1],
        itemsPerPageAllText: $t('itemsPerPageAllText'),
        itemsPerPageText: $t('itemsPerPageText'),
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right',
      }">
      <template #header="{}">
        <thead>
          <tr>
            <td style="border: 1px solid rgb(216, 216, 216);">
            <th>{{ headers[0].text }}</th>
            </td>
            <td style="border: 1px solid rgb(216, 216, 216);">
            <th>{{ headers[1].text }}</th>
            </td>
            <td style="border: 1px solid rgb(216, 216, 216);">
            <th>{{ headers[2].text }}</th>
            </td>
            <td style="border: 1px solid rgb(216, 216, 216);">
            <th>{{ headers[3].text }}</th>
            </td>
            <td style="border: 1px solid rgb(216, 216, 216);">
            <th>{{ headers[4].text }}</th>
            </td>
            <td style="border: 1px solid rgb(216, 216, 216);">
              <table>
                <thead>
                  <tr class="table-rep">
                    <th v-for="i in info">{{ i.text }}</th>
                  </tr>
                </thead>
              </table>
            </td>
          </tr>
        </thead>
      </template>
      <template v-slot:item.id="{ item, index }">
        <td>{{ index + 1 }}</td>
      </template>
      <template v-slot:item.date="{ item}">
        <td>{{ item.date}}</td>
      </template>
      <template v-slot:item.file="{ item }">
        <a style="width: 180px;" class="button" v-for="(file, index) in item.files" :key="index"
          :href="`${$store.state.backend_url}staffs/file-download/` + file.id" download>
          {{ file.file_name }}
        </a>
      </template>
      <template v-slot:item.info="{ item }">

        <v-data-table hide-default-footer hide-default-header :headers="info" :items="item.doers" class="table-rep">

        </v-data-table>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      items: [],
      loading: false,
      endDate: "",
      startDate: "",
      menu1: false,
      menu2: false,
    };
  },
  methods: {
    async getList() {
      this.loading = true;
      try {
        const res = await axios.get(
          `${this.$store.state.backend_url
          }api/reports/execution?page=1&rows=20&document_date=${this.startDate ? `${this.startDate} 00:00:00` : ""
          }|${this.endDate ? `${this.endDate} 23:59:59` : ""}`
        );
        const fetchData = res.data;
        const items = [];
        // for (let item of fetchData) {
        //   item.fio_ru = []
        //   item.fio_uz_cyril = []
        //   item.fio_uz_latin = []
        //   item.department_ru = []
        //   item.department_uz_cyril = []
        //   item.department_uz_latin = []
        //   item.due_date = []
        //   item.yesNo = []
        //   item.due_at = []
        //   item.date = this.getDate(item.date)
        //   for (let doer of item.doers) {
        //     item.fio_ru.push(doer.fio_ru)
        //     item.fio_uz_cyril.push(doer.fio_uz_cyril)
        //     item.fio_uz_latin.push(doer.fio_uz_latin)
        //     item.department_ru.push(doer.department_ru)
        //     item.department_uz_cyril.push(doer.department_uz_cyril)
        //     item.department_uz_latin.push(doer.department_uz_latin)
        //     item.due_date.push(doer.due_date)
        //     item.yesNo.push(this.$t(
        //       `reports_executions.${doer.due_at ? "yes" : "no"}`
        //     )),
        //       item.due_at.push(doer.due_at)
        //   }
        // }

        // fetchData.forEach((item) => {


        //   item.doers.forEach((doer) => {
        //     items.push({
        //       id: items.length + 1,
        //       date: this.getDate(item.date),
        //       number: item.number,
        //       type_name_ru: item.type_name_ru,
        //       type_name_uz_cyril: item.type_name_uz_cyril,
        //       type_name_uz_latin: item.type_name_uz_latin,
        //       fio_ru: doer.fio_ru,
        //       fio_uz_cyril: doer.fio_uz_cyril,
        //       fio_uz_latin: doer.fio_uz_latin,
        //       department_ru: doer.department_ru,
        //       department_uz_cyril: doer.department_uz_cyril,
        //       department_uz_latin: doer.department_uz_latin,
        //       due_date: doer.due_date,
        //       yesNo: this.$t(
        //         `reports_executions.${doer.due_at ? "yes" : "no"}`
        //       ),
        //       due_at: doer.due_at,
        //       files: item.files,
        //     });
        //   });
        // });



        this.loading = false;

        this.items = fetchData;
        console.log(this.items);
      } catch (error) {
        console.dir({ error });
      }
      this.loading = false;
    },
    getDoers(item, field) {
      return item.doers?.map((doer) => doer?.[field]).join(", ") || "";
    },
    handleClick() {
      console.log(this.startDate, this.endDate);
      this.getList();
    },
    handleDownload() {
      axios({
        url: `${this.$store.state.backend_url}api/reports/execution/getexel?page=1&rows=20`,
        method: "GET",
        responseType: "blob",
        headers: { Accept: "application/vnd.ms-excel" },
        params: {
          document_date: `${this.startDate ? `${this.startDate} 00:00:00` : ""
            }|${this.endDate ? `${this.endDate} 23:59:59` : ""}`,
        },
      })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "Excel.xlsx");

          document.body.appendChild(fileLink);

          fileLink.click();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDate(data) {
      if (!data) {
        return "";
      }
      const date = new Date(data);
      const yyyy = date.getFullYear();
      const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const dd = String(date.getDate()).padStart(2, "0");
      return `${yyyy}-${mm}-${dd}`;
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "No",
          value: "id",
          align: 'center'
        },
        {
          text: this.$t("reports_executions.date"),
          value: "date",
        },
        {
          text: this.$t("reports_executions.number"),
          value: "number",
          align: 'center'
        },
        {
          text: this.$t("reports_executions.type"),
          value: this.$t("reports_executions.type_name"),
          align: 'center'
        },
        {
          text: this.$t("reports_executions.file_doc"),
          value: "file",
        },


        {
          text: this.$t("IJRO"),
          value: this.$t("info"),
        },

      ];
    },

    info() {
      return [
        {
          text: this.$t("reports_executions.fullname"),
          value: this.$t("reports_executions.fio"),
        },
        {
          text: this.$t("reports_executions.department_head"),
          value: this.$t("reports_executions.department"),
        },
        {
          text: this.$t("reports_executions.due"),
          value: "due_date",
        },

        {
          text: this.$t("reports_executions.yesNo"),
          value: "yesNo",
        },
        {
          text: this.$t("reports_executions.at"),
          value: "due_at",
        },
        {
          text: this.$t("reports_executions.file"),
          value: "",
        },
      ]
    },

    headerInfo() {
      return {
        text1: this.$t("reports_executions.fullname"),

        text2: this.$t("reports_executions.department_head"),

        text3: this.$t("reports_executions.due"),


        text4: this.$t("reports_executions.yesNo"),

        text5: this.$t("reports_executions.at"),

        text6: this.$t("reports_executions.file"),

      }
    }

  },
  mounted() {
    this.getList();
  },
};
</script>

<style>
.table-report .button {
  /* width: 300px; */
  font-size: 12px;
  padding: 2px 5px;
  margin: 5px 10px;
  border-radius: 10px;
  border: 1px solid rgb(0, 0, 0);
  display: block;
  color: black;
  text-decoration: none;
  align-items: center;
  display: flex;
  justify-content: center;
}

.table-rep .v-data-table__wrapper table td {
  width: 18%;
}

.table-rep th {
  width: 18%;
}

.button .td-width {
  width: 10px;
}

.date-filter-container .date-picker {
  display: flex;
  align-items: center;
  gap: 5px;
}

.date-filter-container .pointer {
  cursor: pointer;
}

.date-filter-container .v-text-field__slot {
  padding: 4px !important;
}
</style>
