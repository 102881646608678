var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"calc(10px + 1vw)"}},[_c('div',[_c('h2',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(_vm.$t("reports_executions.title")))])]),_c('div',[_c('v-row',{staticClass:"date-filter-container"},[_c('v-col',{staticClass:"date-picker",attrs:{"cols":"5"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('document.start_date'),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('p',{staticClass:"pointer",on:{"click":function($event){_vm.startDate = ''}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-col',{staticClass:"date-picker",attrs:{"cols":"5"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('document.end_date'),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},[_c('v-spacer')],1)],1),_c('p',{staticClass:"pointer",on:{"click":function($event){_vm.endDate = ''}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-col',{staticClass:"d-flex justify-space-between",attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.handleClick}},[_vm._v(" "+_vm._s(_vm.$t("search"))+" ")]),_c('v-btn',{attrs:{"depressed":"","color":"primary px-2"},on:{"click":_vm.handleDownload}},[_vm._v(" "+_vm._s(_vm.$t("download"))+" ")])],1)],1)],1),_c('v-data-table',{staticClass:"table-report",attrs:{"hide-default-header":"","headers":_vm.headers,"items":_vm.items,"items-per-page":15,"footer-props":{
      itemsPerPageOptions: [20, 50, 100, -1],
      itemsPerPageAllText: _vm.$t('itemsPerPageAllText'),
      itemsPerPageText: _vm.$t('itemsPerPageText'),
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-arrow-left',
      nextIcon: 'mdi-arrow-right',
    }},scopedSlots:_vm._u([{key:"header",fn:function(ref){return [_c('thead',[_c('tr',[_c('td',{staticStyle:{"border":"1px solid rgb(216, 216, 216)"}},[_c('th',[_vm._v(_vm._s(_vm.headers[0].text))])]),_c('td',{staticStyle:{"border":"1px solid rgb(216, 216, 216)"}},[_c('th',[_vm._v(_vm._s(_vm.headers[1].text))])]),_c('td',{staticStyle:{"border":"1px solid rgb(216, 216, 216)"}},[_c('th',[_vm._v(_vm._s(_vm.headers[2].text))])]),_c('td',{staticStyle:{"border":"1px solid rgb(216, 216, 216)"}},[_c('th',[_vm._v(_vm._s(_vm.headers[3].text))])]),_c('td',{staticStyle:{"border":"1px solid rgb(216, 216, 216)"}},[_c('th',[_vm._v(_vm._s(_vm.headers[4].text))])]),_c('td',{staticStyle:{"border":"1px solid rgb(216, 216, 216)"}},[_c('table',[_c('thead',[_c('tr',{staticClass:"table-rep"},_vm._l((_vm.info),function(i){return _c('th',[_vm._v(_vm._s(i.text))])}),0)])])])])])]}},{key:"item.id",fn:function(ref){
    var item = ref.item;
    var index = ref.index;
return [_c('td',[_vm._v(_vm._s(index + 1))])]}},{key:"item.date",fn:function(ref){
    var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.date))])]}},{key:"item.file",fn:function(ref){
    var item = ref.item;
return _vm._l((item.files),function(file,index){return _c('a',{key:index,staticClass:"button",staticStyle:{"width":"180px"},attrs:{"href":(_vm.$store.state.backend_url) + "staffs/file-download/" + file.id,"download":""}},[_vm._v(" "+_vm._s(file.file_name)+" ")])})}},{key:"item.info",fn:function(ref){
    var item = ref.item;
return [_c('v-data-table',{staticClass:"table-rep",attrs:{"hide-default-footer":"","hide-default-header":"","headers":_vm.info,"items":item.doers}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }